import { logEvent } from 'firebase/analytics';

import { getTranscriptStats } from '../actions';
import { getAnalyticsInstance } from '../firebase';
import { selectAllMicsSelected } from '../selectors/audioV2';
import { selectFirebaseUser } from '../selectors/auth';
import { selectInTwilioCalls } from '../selectors/conversation';
import {
  selectConversationEndInitiatedByCurrentUser,
  selectEndedDueToConvoRestrictedSession,
} from '../selectors/conversationEnd';
import { selectSpeakersMap, selectStatus, selectTranscripts } from '../selectors/legacy-conversation';
import { selectAccountType, selectAvaId, selectFeatures, selectFeedbackMetrics } from '../selectors/userProfile';
import {
  getConversationCloseReason,
  getConversationTimeInSeconds,
  getMetricsToUpdate,
  getRatingMetricsToUpdate,
  isFirstConvo,
  isHost,
  isScribeRequester,
  updateConversationMetrics,
} from '../services/conversation-metrics';
import { fetchUserProfile } from '../store/slices/userProfile';
import type { AppDispatch, RootState } from '../store/store';
import type { FeatureMap } from '../types';
import * as segment from './segment';
import { updateConvoMetricsThroughWorker } from './sw';

export const getBlocs = ({ speakers, transcripts }: { speakers: any; transcripts: any }) => {
  const conversationBlocs = [];

  Object.keys(transcripts).forEach((transcriptId) => {
    const currentTranscript = transcripts[transcriptId];

    const bloc: Record<string, any> = {};
    bloc.asr = {
      final: currentTranscript['asr.final'],
      timeReceivedMs: currentTranscript['asr.timeReceivedMs'],
    };
    bloc.blocId = currentTranscript.id;
    bloc.environment = 'production';
    bloc.final = currentTranscript.final;
    bloc.id = currentTranscript.id;
    bloc.lastHumanIndexes = currentTranscript.indexes;
    bloc.speaker = (speakers[currentTranscript.author] || {}).avaName;
    bloc.speakerId = currentTranscript.author;
    bloc.speechLang = currentTranscript.speechLang;
    bloc.text = currentTranscript.texts;
    bloc.usePaidASR = currentTranscript.usePaidASR;

    //@ts-ignore
    conversationBlocs.push(bloc);
  });

  return conversationBlocs;
};

export const getIsAllowedAccess = ({ status, features }: { status: any; features: FeatureMap }) => {
  let isAllowedAccess = status.confidential.allowTranscriptsSaving;

  const hasSaveTranscript = features.saveTranscript;
  const hasForbidConversationSaving = features['forbid-conversation-saving'];

  const { id: roomId, host } = status;

  if (hasForbidConversationSaving || !hasSaveTranscript) {
    isAllowedAccess = false;
  } else if (roomId.indexOf(host.avaId) !== 0) {
    if (host.flags['forbid-conversation-sharing']) {
      isAllowedAccess = false;
    } else {
      isAllowedAccess = isAllowedAccess || host.flags.saveTranscript;
    }
  }

  return isAllowedAccess;
};

export const getSpeakers = ({ speakers }: { speakers: any }) => {
  const speakersArr = [];

  Object.keys(speakers).forEach((speakerId) => {
    const currentSpeaker = speakers[speakerId];

    const speaker: Record<string, any> = {};
    speaker.avaId = speakerId;
    speaker.avaName = currentSpeaker.avaName;
    speaker.theme = currentSpeaker.theme;
    speaker.userName = currentSpeaker.userName;
    speaker.userAvatar = currentSpeaker.userAvatar;

    //@ts-ignore
    speakersArr.push(speaker);
  });

  return speakersArr;
};

export const updateMetricsAndRefetchProfile = async (
  metricsToUpdate: any,
  avaId: string,
  firebaseAuthUID: string,
  dispatch: AppDispatch
) => {
  // We want to let the service worker do the long
  // running queries, especially if we're closing the window!
  try {
    updateConvoMetricsThroughWorker(avaId, firebaseAuthUID, metricsToUpdate);
  } catch {
    // If the user is closing the window, this will not work
    // as it's a promise, long running query
    await updateConversationMetrics({ metricsToUpdate, avaId, firebaseAuthUID });
  }
  dispatch(fetchUserProfile());
};

export const trackHadConversationFromState = async (state: RootState) => {
  const features = selectFeatures(state);
  const dispatch = window.store.dispatch;
  const transcriptStats = getTranscriptStats()(dispatch, () => state);
  return trackHadConversation({
    allMicsSelected: selectAllMicsSelected(state),
    conversationStatus: selectStatus(state),
    endedByCurrentUser: selectConversationEndInitiatedByCurrentUser(state),
    endedDueToConvoRestrictedSession: selectEndedDueToConvoRestrictedSession(state),
    inTwilioCalls: selectInTwilioCalls(state),
    isTranscriptSaved: features.saveTranscript && !features['forbid-conversation-saving'],
    transcriptStats,
    avaId: selectAvaId(state),
    feedbackMetrics: selectFeedbackMetrics(state),
    accountType: selectAccountType(state),
    firebaseAuthUID: selectFirebaseUser(state)?.uid || '',
    dispatch: dispatch,
  });
};

export const trackHadConversation = async ({
  allMicsSelected,
  conversationStatus,
  endedByCurrentUser,
  endedDueToConvoRestrictedSession,
  isTranscriptSaved,
  inTwilioCalls,
  transcriptStats,
  avaId,
  accountType,
  feedbackMetrics,
  firebaseAuthUID,
  dispatch,
}: {
  allMicsSelected: string[];
  conversationStatus: any;
  endedByCurrentUser: boolean;
  endedDueToConvoRestrictedSession: boolean;
  isTranscriptSaved: boolean;
  inTwilioCalls: boolean;
  transcriptStats: any;
  avaId: string | undefined;
  feedbackMetrics: any;
  accountType: string | undefined;
  firebaseAuthUID: string;
  dispatch: AppDispatch;
}) => {
  if (!avaId) return;
  const { conversationMode, host, id: convoChannel, scribe, speakers, startTimestampMs } = conversationStatus;
  const { wordsTypedYou, wordsTypedGroup, wordsCapturedYou, wordsCapturedGroup } = transcriptStats;

  const conversationTimeInSeconds = getConversationTimeInSeconds(startTimestampMs);

  const currentMetrics = feedbackMetrics;
  const closeReason = getConversationCloseReason({
    currentUserAvaId: avaId || '',
    endedByCurrentUser,
    endedDueToConvoRestrictedSession,
    host,
  });
  const metricsToUpdate = getMetricsToUpdate({
    conversationTimeInSeconds,
    isSoloConvo: speakers?.length === 1,
    //@ts-ignore
    startTimestampMs: startTimestampMs,
    transcriptSaved: isTranscriptSaved,
    transcriptStats,
  });

  if (isFirstConvo(currentMetrics)) {
    segment.identifyWithId(avaId || '', { First_convo_at: new Date() });
  }

  segment.track('Had Conversation', {
    'Convo Time': conversationTimeInSeconds,
    'Convo Channel': convoChannel,
    IsFirstConvo: isFirstConvo(currentMetrics),
    IsHost: isHost({ host, avaId: avaId || '' }),
    Scribe: !!scribe,
    IsTempUser: accountType === 'Temporary',
    IsScribeRequester: isScribeRequester({ scribe, avaId: avaId || '' }),
    'Close Reason': closeReason,
    'Conversation Mode': conversationMode,
    'Participant Count': speakers?.length,
    'Participant List': speakers?.map((speaker) => `${speaker.userName} / ${speaker.avaId} / ${speaker.avaName}`),
    IsBroadcasted: false,
    IsDialIn: inTwilioCalls,
    TranscriptSaved: isTranscriptSaved,
    'Words Typed You': wordsTypedYou,
    'Words Typed Group': wordsTypedGroup,
    'Words Captured You': wordsCapturedYou,
    'Words Captured Group': wordsCapturedGroup,
    microphone_selected: allMicsSelected,
  });
  logEvent(getAnalyticsInstance(), 'app_had_conversation', {
    words_captured_group: wordsCapturedGroup,
    words_captured_you: wordsCapturedYou,
    participant_count: speakers?.length,
    convo_scribed: !!scribe,
    is_scribe_requester: isScribeRequester({ scribe, avaId: avaId || '' }),
    is_host: isHost({ host, avaId: avaId || '' }),
    convo_time: conversationTimeInSeconds,
  });

  updateMetricsAndRefetchProfile(metricsToUpdate, avaId, firebaseAuthUID, dispatch);
};

export const trackConversationRating = async ({
  rate,
  status,
  feedbackMetrics,
  avaId,
  firebaseAuthUID,
  dispatch,
}: {
  rate?: number;
  status: any;
  feedbackMetrics: any;
  avaId: string | undefined;
  firebaseAuthUID: string;
  dispatch: AppDispatch;
}) => {
  const { id: convoChannel } = status;
  const currentMetrics = feedbackMetrics;
  const metricsToUpdate = getRatingMetricsToUpdate({ rate });

  segment.track('Rated', {
    convo_channel: convoChannel,
    rating: rate || 0,
    is_first_5_star: rate && rate === 5 && !currentMetrics?.Rated_5,
  });

  //@ts-ignore
  updateMetricsAndRefetchProfile(metricsToUpdate, avaId, firebaseAuthUID, dispatch);
};

export const prepareTranscriptAfterConversation = (state: RootState) => {
  const timestamp = Date.now();

  const speakers = selectSpeakersMap(state);
  const transcripts = selectTranscripts(state);
  const status = selectStatus(state);
  const features = selectFeatures(state);

  const blocs = getBlocs({ speakers, transcripts });
  const isAllowedAccess = getIsAllowedAccess({ status, features });
  const convoSpeakers = Object.values(speakers);
  const convoTranscripts = getBlocs({ speakers, transcripts }).length > 0;

  return {
    blocs,
    conversationMode: status.conversationMode,
    endTimestampMs: timestamp,
    env: 'production',
    hasTranscripts: Object.keys(transcripts).length > 0,
    isAllowedAccess,
    langs: status.langs,
    links: status.links,
    roomId: status.id,
    speakers: convoSpeakers,
    startTimestampMs: status.startTimestampMs,
    t0: status.startTimestampMs,
    t1: timestamp,
    title: status.title,
    custom_title: status.title,
    transcripts: convoTranscripts,
  };
};

export const navigateToTranscriptURLIfNeeded = () => {
  if (window.isElectron) {
    if (window.location.pathname !== '/cc/conversation') {
      //@ts-ignore
      window.navigate('/cc/conversation');
    }
  } else {
    if (
      window.location.pathname !== '/web/transcript' &&
      window.location.pathname !== '/convo-v1/transcript' &&
      window.location.pathname !== '/convo-v2/transcript'
    ) {
      //@ts-ignore
      window.navigate('/web/transcript');
    }
  }
};
