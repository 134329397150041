import { trace } from 'firebase/performance';

import { getPerformanceInstance } from '../firebase';
import * as segment from './segment';

type Stopwatch = {
  startTimestamp: number;
  traceInstance?: ReturnType<typeof trace>;
};

const globals: { stopWatchData: { [name: string]: Stopwatch } } = globalThis as any;
if (!globals.stopWatchData) {
  globals.stopWatchData = {};
}

const STOPWATCH_DATA: { [name: string]: Stopwatch } = globals.stopWatchData;

if (localStorage.getItem('stopwatch_index_init')) {
  STOPWATCH_DATA['init'] = {
    startTimestamp: parseInt(localStorage.getItem('stopwatch_index_init') ?? '0'),
  };
}

export const startStopwatch = (name: string) => {
  const traceInstance = trace(getPerformanceInstance(), name);
  traceInstance.start();
  STOPWATCH_DATA[name] = { startTimestamp: Date.now(), traceInstance };
};

export const stopAndTrack = (name: string, properties: { [key: string]: string | boolean } = {}) => {
  const stopwatch = STOPWATCH_DATA[name];
  const elapsed = stopwatch && Date.now() - stopwatch.startTimestamp;

  if (stopwatch) {
    for (const [key, value] of Object.entries(properties)) {
      stopwatch.traceInstance?.putAttribute(key, value.toString());
    }
    stopwatch.traceInstance?.stop();

    if (!stopwatch.traceInstance) {
      segment.track(`Web - Stopwatch ${name}`, { ...properties, elapsed }, {});
    }
  }

  delete STOPWATCH_DATA[name];

  if (process.env.LOG_TIMINGS) {
    console.log(`Stopwatch '${name}': ${stopwatch ? elapsed + 'ms elapsed' : 'stopped but not started'}`);
  }
};
