import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';

import { getFirestoreInstance } from './index';

export const deleteTranscriptFromUser = async ({ userId, roomId }: { userId: string; roomId: string }) => {
  const firestore = await getFirestoreInstance();
  const usersCollection = collection(firestore, 'users');
  const userRef = doc(usersCollection, userId);
  const userSnapshot = await getDoc(userRef);
  if (!userSnapshot.exists()) return;

  const roomRef = doc(collection(userRef, 'rooms'), roomId);
  const roomSnapshot = await getDoc(roomRef);
  if (!roomSnapshot.exists) return;

  await updateDoc(roomRef, { deleted: true });
};

export const deleteManyTranscriptsFromUser = async ({ userId, roomIds }: { userId: string; roomIds: string[] }) => {
  const firestore = await getFirestoreInstance();
  const usersCollection = collection(firestore, 'users');
  const userRef = doc(usersCollection, userId);
  const userSnapshot = await getDoc(userRef);
  if (!userSnapshot.exists) return;

  const deleteTranscript = async (roomId: string) => {
    const roomRef = doc(collection(userRef, 'rooms'), roomId);
    const roomSnapshot = await getDoc(roomRef);
    if (!roomSnapshot.exists) return;

    await updateDoc(roomRef, { deleted: true });

    return roomId;
  };

  const deletedIds = await Promise.all(roomIds.map(deleteTranscript));

  return deletedIds;
};

export const updateTranscriptTitleForUser = async ({
  userId,
  roomId,
  title,
}: {
  userId: string;
  roomId: string;
  title: string;
}) => {
  if (!title) return;

  const firestore = await getFirestoreInstance();
  const usersCollection = collection(firestore, 'users');
  const userRef = doc(usersCollection, userId);
  const userSnapshot = await getDoc(userRef);
  if (!userSnapshot.exists) return;

  const roomRef = doc(collection(userRef, 'rooms'), roomId);
  const roomSnapshot = await getDoc(roomRef);
  if (!roomSnapshot.exists) return;

  await updateDoc(roomRef, { title });
};
