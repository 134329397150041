import type { TtsGender, TtsVoice } from '../../store/slices/textToSpeech';
import { firebaseFunctionsEndpoint, getAvaBackendEndpoint, httpRequestWithToken } from '../../utils/http';

const patchUser = async ({
  avaId,
  firebaseAuthUID,
  channel,
  data,
}: {
  avaId: string;
  firebaseAuthUID: string;
  channel?: string;
  data: any;
}) => {
  return httpRequestWithToken({
    url: getUsersUrl(avaId, 'v1.1', firebaseAuthUID, channel),
    method: 'PATCH',
    payload: data,
  });
};

const updateMetrics = async ({
  avaId,
  firebaseAuthUID,
  convoMetrics,
}: {
  avaId: string;
  firebaseAuthUID: string;
  convoMetrics: any;
}) => patchUser({ avaId, firebaseAuthUID, data: { convoMetrics } });

// Updates the user's feature flags.
//
// The database refers to this value as 'flags', while the application
// state refers to this as features.
// TODO: Should update the application state to refer to user's features
// as either featureFlags or just flags.
const updateFeatures = async ({
  avaId,
  firebaseAuthUID,
  features,
}: {
  avaId: string;
  firebaseAuthUID: string;
  features: any;
}) => patchUser({ avaId, firebaseAuthUID, data: { flags: features } });

const updateUserName = async ({
  avaId,
  firebaseAuthUID,
  userName,
}: {
  avaId: string;
  firebaseAuthUID: string;
  userName: string;
}) => patchUser({ avaId, firebaseAuthUID, data: { userName } });

const updateHearingProfile = async ({
  avaId,
  firebaseAuthUID,
  hearingProfile,
}: {
  avaId: string;
  firebaseAuthUID: string;
  hearingProfile: number;
}) => {
  // NOTE: There are 3 main hearing profiles (deaf, hard-of-hearing, hearing)
  // and 2 other sub profiles. Since it was not part of the design to implement
  // all 5 profiles, this is hard-coded for now. If we do implement all 5
  // profiles, then we can use HEARING_PROFILE in utils/user.js
  if (!(hearingProfile >= 0 && hearingProfile <= 4)) {
    throw new Error('Invalid hearing profile');
  }

  return patchUser({ avaId, firebaseAuthUID, data: { hearingProfile } });
};

const updateJoinMetrics = async ({
  avaId,
  firebaseAuthUID,
  joinMetrics,
}: {
  avaId: string;
  firebaseAuthUID: string;
  joinMetrics: any;
}) => patchUser({ avaId, firebaseAuthUID, data: { joinMetrics } });

const updateInviteMetrics = async ({
  avaId,
  firebaseAuthUID,
  inviteMetrics,
}: {
  avaId: string;
  firebaseAuthUID: string;
  inviteMetrics: any;
}) => patchUser({ avaId, firebaseAuthUID, data: { inviteMetrics } });

const updateSettings = async ({
  avaId,
  firebaseAuthUID,
  settings,
}: {
  avaId: string;
  firebaseAuthUID: string;
  settings: any;
}) => patchUser({ avaId, firebaseAuthUID, data: { settings } });

const updateRole = async ({ avaId, firebaseAuthUID, role }: { avaId: string; firebaseAuthUID: string; role: string }) =>
  patchUser({ avaId, firebaseAuthUID, data: { role } });

const getOrCreateProfile = async ({ firebaseAuthUID, channel }: { firebaseAuthUID: string; channel: string }) => {
  // to future engineers: we used to prefetch the token in index.html but it was a security vulnerability, please don't
  return patchUser({ avaId: 'me', firebaseAuthUID, channel, data: {} });
};

const getProfile = async ({ user }: { user?: any }) => {
  const firebaseAuthUID = user ? user.firebaseAuthUID || user.id : null;
  const url = getUsersUrl('me', 'v1', firebaseAuthUID);
  return httpRequestWithToken({
    url,
    method: 'GET',
  });
};

const getGuestProfile = async ({
  avaId,
  firebaseAuthUID,
}: {
  avaId: string;
  firebaseAuthUID: string | null | undefined;
}) => {
  let url = `${getAvaBackendEndpoint()}/api/v1/guest-users/${avaId}`;
  if (firebaseAuthUID) {
    url = `${url}?firebaseAuthUID=${firebaseAuthUID}`;
  }

  return httpRequestWithToken({
    url,
    method: 'GET',
  });
};

const createGuestProfile = async ({
  roomId,
  firebaseAuthUID,
}: {
  roomId: string;
  firebaseAuthUID: string | null | undefined;
}) => {
  let url = `${getAvaBackendEndpoint()}/api/v1/guest-users/me`;
  if (firebaseAuthUID) {
    url = `${url}?firebaseAuthUID=${firebaseAuthUID}`;
  }

  return httpRequestWithToken({
    url,
    method: 'POST',
    payload: { roomId },
  });
};

export const getTurnCredentials = async () => {
  const url = `${firebaseFunctionsEndpoint}/turnCredentials`;
  try {
    const result = await httpRequestWithToken({
      url,
      method: 'GET',
    });
    if (result && result.data && result.data.ok) {
      return result.data.turnCredentials || [];
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const beginChatWithUser = async (avaId: string, msg: string, firebaseAuthUID: string) => {
  // firebaseAuthUID is the firebase UID of the SCRIBE, not the user being chatted with
  const url = `${getAvaBackendEndpoint()}/api/v1.1/users/beginChat?firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken({
    url,
    method: 'POST',
    payload: { avaId, messageFromUser: '👋', messageToUser: msg },
  });
};

const getUsersUrl = (
  avaId: string,
  version: string,
  firebaseAuthUID?: string | null,
  channel?: string | null
): string => {
  const url = `${getAvaBackendEndpoint()}/api/${version}/users/${avaId}`;
  if (firebaseAuthUID) {
    if (channel) {
      return `${url}?firebaseAuthUID=${firebaseAuthUID}&channel=${channel}`;
    }
    return `${url}?firebaseAuthUID=${firebaseAuthUID}`;
  }
  return url;
};

const terminateCall = async ({ avaId, sid, uid }: { avaId: any; sid: string; uid: string }) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/calls/${sid}?avaId=${avaId}&firebaseAuthUID=${uid}`;
  return httpRequestWithToken({ url, method: 'DELETE' });
};

const createTwilioCall = async ({
  avaId,
  calleeInternationalNumber,
  conferenceType,
  roomId,
  sendDigits,
  speechLang,
  firebaseAuthUID,
}: {
  avaId: string;
  calleeInternationalNumber: string;
  conferenceType: string | null | undefined;
  roomId: string;
  sendDigits: any[];
  speechLang: string;
  firebaseAuthUID: string;
}) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/calls?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}`;

  return httpRequestWithToken({
    url,
    method: 'POST',
    payload: {
      roomId,
      calleeInternationalNumber,
      sendDigits,
      speechLang,
      conferenceType,
    },
  });
};

const findOrganizations = async ({
  firebaseAuthUID,
  orgName,
  orgType,
}: {
  firebaseAuthUID: string;
  orgName: string;
  orgType: string;
}) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/find-organizations?keywords=${orgName}&firebaseAuthUID=${firebaseAuthUID}&orgType=${orgType}`;
  return httpRequestWithToken({
    url,
    method: 'GET',
  });
};

const getUserCredits = async ({ avaId, firebaseAuthUID }: { avaId: string; firebaseAuthUID: string }) => {
  const url = `${getAvaBackendEndpoint()}/api/v1.1/user-credits/${avaId}?avaId=${avaId}&firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken({
    url,
    method: 'GET',
  });
};

const getRoomId = async ({ token }: { token: string }) =>
  httpRequestWithToken({ url: `${getAvaBackendEndpoint()}/api/v1/tokens/${token}`, method: 'GET' });

const saveReport = async ({ report, firebaseAuthUID }: { report: any; firebaseAuthUID: string }) =>
  httpRequestWithToken({
    url: `${getAvaBackendEndpoint()}/api/v1/reports?firebaseAuthUID=${firebaseAuthUID}`,
    method: 'POST',
    payload: report,
  });

const getTtsVoices = async ({
  firebaseAuthUID,
  lang,
  gender,
  voiceId,
}: {
  firebaseAuthUID: string;
  lang?: string;
  gender?: TtsGender;
  voiceId?: string;
}) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-voices-sample?firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken<TtsVoice[]>({
    url,
    method: 'POST',
    payload: { lang, gender, voiceId },
  });
};

const getTtsGenderPreference = async ({ firebaseAuthUID }: { firebaseAuthUID: string }) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-gender-preference?firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken({
    url,
    method: 'GET',
  });
};

const postTtsGenderPreference = async ({ firebaseAuthUID, gender }: { firebaseAuthUID: string; gender: TtsGender }) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-gender-preference?firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken({
    url,
    method: 'POST',
    payload: { gender },
  });
};

const postTtsVoicePreference = async ({ firebaseAuthUID, voiceId }: { firebaseAuthUID: string; voiceId: string }) => {
  const url = `${getAvaBackendEndpoint()}/api/v1/users/tts-voice?firebaseAuthUID=${firebaseAuthUID}`;
  return httpRequestWithToken({
    url,
    method: 'POST',
    payload: { voiceId },
  });
};

export const users = {
  getOrCreateProfile,
  getProfile,
  updateFeatures,
  updateHearingProfile,
  updateInviteMetrics,
  updateJoinMetrics,
  updateMetrics,
  updateRole,
  updateSettings,
  updateUserName,
} as const;

export const tts = {
  getTtsVoices,
  getTtsGenderPreference,
  postTtsVoicePreference,
  postTtsGenderPreference,
} as const;

export const guestUsers = {
  getGuestProfile,
  createGuestProfile,
} as const;

export const userCredits = {
  getUserCredits,
} as const;

export const conversations = {
  getRoomId,
} as const;

export const calls = {
  createTwilioCall,
  terminateCall,
} as const;

export const organizations = {
  findOrganizations,
} as const;

export const reports = {
  saveReport,
} as const;
