import { logEvent } from 'firebase/analytics';
import i18n from 'i18next';
import { call, put, select } from 'redux-saga/effects';

import {
  deleteLicence as deleteAction,
  newLicenceFailure,
  newLicenceSuccess,
  newTrial,
  updateLicence as updateAction,
} from '../../actions/account/licence';
import { fetchUserInfos } from '../../actions/account/user';
import { getAnalyticsInstance } from '../../firebase';
import * as avaApi from '../../services/api/ava';
import { licences } from '../../services/api/saas';
import { triggerOrgTrial } from '../../services/api/subscription';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import type { RootState } from '../../store/store';
import * as segment from '../../utils/segment';
import { getPlanType } from '../../utils/status';

const getLicence = (state: RootState, id: string) =>
  state.account.licence.licences ? state.account.licence.licences.find((el) => el.id === id) : null;
const getOffer = (state: RootState, id: string) =>
  state.account.licence.offers ? state.account.licence.offers.find((el) => el.id === id) : null;

export function* startTrial() {
  try {
    const role = window.localStorage.getItem('account_role');
    const { firebaseAuthUID, avaId } = yield select((state: RootState) => ({
      firebaseAuthUID: state.auth.firebaseUser?.uid,
      avaId: state.userProfile.parse?.avaId,
    }));
    //@ts-ignore
    yield call(avaApi.users.updateRole, {
      avaId: window.localStorage.getItem('avaId') || '',
      firebaseAuthUID: firebaseAuthUID,
      role: role,
    });
    yield call(triggerOrgTrial, avaId, firebaseAuthUID);

    if (role === 'admin') {
      yield put(newTrial.success());
      //window.browserHistory.push('/account/dashboard');
      //@ts-ignore
      window.navigate('/account/dashboard');
    } else {
      yield window.open('/', '_self');
    }
  } catch (error: any) {
    yield put(newTrial.failure(error));
  }
}

export function* updateLicence({ payload }: any) {
  try {
    const licence = yield select(getLicence, payload.id);
    const { language } = i18n;

    if (!payload.email || payload.email.length === 0) {
      const data = {
        id: payload.id,
        licence,
        language,
      } as const;
      const res = yield call(licences.unassignLicence, data);

      yield put(updateAction.success(res.data));
      yield put(fetchUserInfos.request());
    } else {
      const data = {
        id: payload.id,
        licence,
        recipient: { email: payload.email },
        language,
      } as const;
      const res = yield call(licences.updateLicence, data);
      const offer = yield select(getOffer, res.data.offerId);
      if (offer) {
        const period = offer?.interval && `${offer.interval}ly`;
        yield call(segment.track, 'License Assigned', {
          'Assignee Email': res.data.recipient.email,
          'Assignee License Period': period.charAt(0).toUpperCase() + period.slice(1),
          'Assignee License Type': offer?.metadata?.category,
        });
      }

      yield put(updateAction.success(res.data));
      yield put(fetchUserInfos.request());
    }
  } catch (err: any) {
    yield put(
      //@ts-ignore
      updateAction.failure({
        licenceId: payload.id,
        response: err.response,
      })
    );
  }
}

export function* postLicence({ payload }: any) {
  try {
    const { language } = i18n;
    const trial = window.localStorage.getItem('account_trial') || 15;
    const plan = yield select(getOffer, payload.planId);
    const period = plan?.interval && `${plan.interval}ly`;

    const data = {
      licence: payload,
      language,
      trial,
    } as const;
    const res = yield call(licences.createLicence, data);
    yield put(newLicenceSuccess(res.data.licences));

    yield call(segment.track, 'License Purchased', {
      'License Count': payload.quantity,
      'License Period': period.charAt(0).toUpperCase() + period.slice(1),
      'License Total Payment': (plan.amount / 100) * payload.quantity,
      'License Type': plan?.metadata?.category,
    });

    //@ts-ignore
    window.navigate('/account/payment-succeed');
  } catch (err: any) {
    yield put(newLicenceFailure(err.response.data));
  }
}

export function* deleteLicense({ payload }: any) {
  try {
    const { language } = i18n;

    const data = {
      licence: payload,
      language,
    } as const;
    //@ts-ignore
    const res = yield call(licences.deleteLicence, data);

    yield put(deleteAction.success({ ...payload, res }));
  } catch (err: any) {
    yield put(deleteAction.failure(err.response));
  }
}

export function* openCancelLicenceTypeform() {
  const { email, org, subscription } = yield select((state: RootState) => ({
    email: state.account.user.user.email,
    org: state.account.user.user.organization,
    subscription: state.userProfile.subscription,
  }));
  const language = (i18n.language || 'en').split('-')[0];
  const url = `https://ava-me.typeform.com/to/${language === 'fr' ? 'r93HRx' : 'XM4hzr'}?mkt=${
    org.type
  }&email=${email}&orgname=${org.name}&med=acc#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
  logEvent(getAnalyticsInstance(), 'app_submitted_typeform', {
    channel: 'inbound',
    source: 'cancel-license',
    market: org.type,
    organization_name: org.name,
    plan: getPlanType(subscription),
  });
  window.open(url, '_blank');
}
