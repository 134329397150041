import type { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import type { AuthProvider, RecaptchaVerifier, User } from 'firebase/auth';
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  setPersistence,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  signInWithPopup,
} from 'firebase/auth';
import type { Value } from 'firebase/remote-config';

import { getAuthInstance } from '../../firebase';
import * as segment from '../../utils/segment';
import { startStopwatch } from '../../utils/stopwatch';
import { prepareOfflineTracking } from '../../utils/sw';
import type { AppDispatch } from '../store';

type ThemeColors = {
  speaker_color: string;
  text_color: string;
  highlight_color: string;
  background_color: string;
};

export type ThemeColorsKeys = keyof ThemeColors;

type ThemeConfig = {
  light_theme: ThemeColors;
  dark_theme: ThemeColors;
};

type ThemeSettings = {
  solo: ThemeConfig;
  me: ThemeConfig;
  others: ThemeConfig[];
};

export type State = {
  // This slice of state represents all the data directly related to logging in
  // and the authentication process. Any pieces of data related to the user profile,
  // subscription - that could potentially be modified after the login finished -
  // should be kept in userInfo.ts.

  // Whether any of the firebase login methods have been initiated (by token,
  // anonymously, or with provider).
  firebaseAuthInitiated: boolean;
  // Whether the firebase auth callback has been triggered. If firebaseUser
  // is undefined at this point it means that the user is not logged in or their
  // email is unverified.
  firebaseAuthTriggered: boolean;
  firebaseUser: User | undefined;
  firebaseIsNewUser: boolean;

  firebaseIDToken: string | undefined;
  firebaseIDTokenFetched: number | undefined;

  firebaseRemoteConfig: {
    webUseWebRTC: boolean;
    webEnableSSO: boolean;
    webEnableSolodia: boolean;
    soloDiaTimeout: number;
    isTextToSpeechV2: boolean;
    enableConvoV2?: boolean; // undefined if not loaded yet
    enableCCV2?: boolean;
    themeColors?: ThemeSettings;
  };
};

const initialState: State = {
  firebaseAuthInitiated: false,
  firebaseAuthTriggered: false,
  firebaseUser: undefined,
  firebaseIsNewUser: false,
  firebaseIDToken: undefined,
  firebaseIDTokenFetched: undefined,

  firebaseRemoteConfig: {
    webUseWebRTC: false,
    webEnableSSO: false,
    webEnableSolodia: false,
    soloDiaTimeout: 45,
    isTextToSpeechV2: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFirebaseUser(state, { payload }: PayloadAction<User>) {
      state.firebaseUser = payload;
      state.firebaseIsNewUser = payload.metadata.lastSignInTime === payload.metadata.creationTime;
    },
    markFirebaseAuthTriggered(state) {
      state.firebaseAuthTriggered = true;
    },
    markFirebaseLoginInitiated(state) {
      state.firebaseAuthInitiated = true;
    },
    markFirebaseLoginFinished(state) {
      state.firebaseAuthInitiated = false;
    },
    setFirebaseRemoteConfig(state, { payload }: PayloadAction<Record<string, Value>>) {
      state.firebaseRemoteConfig = {
        webUseWebRTC: payload['web_use_webrtc']?.asBoolean() || false,
        webEnableSSO: payload['web_enable_sso']?.asBoolean() || false,
        webEnableSolodia: payload['web_enable_solodia']?.asBoolean() || false,
        soloDiaTimeout: payload['modal_for_participant_amount_timer']?.asNumber() || 45,
        isTextToSpeechV2: payload['is_text_to_speech_v2']?.asBoolean() || false,
        enableConvoV2: payload['convo_v2']?.asBoolean() || false,
        enableCCV2: payload['desktop_v2']?.asBoolean() || false,
        themeColors: (() => {
          const rawThemeColors = payload['theme_colors_v2']?.asString();
          if (rawThemeColors) {
            try {
              return JSON.parse(rawThemeColors) as ThemeSettings;
            } catch (error) {
              console.error('Failed to parse themeColors:', error);
              return undefined;
            }
          }
          return undefined;
        })(),
      };
    },
    clear(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadIDToken.fulfilled, (state, action) => {
      state.firebaseIDToken = action.payload;
      state.firebaseIDTokenFetched = Date.now();
    });
  },
});

export const authReducer = authSlice.reducer;
export const {
  setFirebaseUser,
  markFirebaseLoginInitiated,
  markFirebaseLoginFinished,
  markFirebaseAuthTriggered,
  setFirebaseRemoteConfig,
  clear,
} = authSlice.actions;

// If the user is authenticated with their own email and password, we treat
// them as logged out until they verify their email. This is not true for
// users who logged in with Facebook though - their email will always be
// unverified and we are okay with it.
const shouldWaitForEmailVerification = (firebaseUser: User | null) => {
  if (!firebaseUser) return false;
  const isCredentialiedUser =
    (firebaseUser.providerData?.length ?? 0) === 1 && firebaseUser.providerData[0]?.providerId === 'password';
  return firebaseUser.email && !firebaseUser.emailVerified && isCredentialiedUser;
};

export const handleFirebaseUser = (dispatch: AppDispatch, user: User | null) => {
  const waitForVerification = shouldWaitForEmailVerification(user);
  if (!user?.uid || waitForVerification) {
    segment.track('Firebase User Logged Out');
    return;
  }
  segment.track('Firebase User Logged In', { handleFirebaseUser: true });
  dispatch(setFirebaseUser(user));

  localStorage.setItem('prefetch_me_uid', user.uid);
  dispatch(loadIDToken());
  localStorage.setItem('avaAccount', 'true');
  localStorage.setItem('isAnonymous', user.isAnonymous ? 'true' : 'false');

  Sentry.addBreadcrumb({
    category: 'auth',
    message: JSON.stringify(user),
    level: Sentry.Severity.Info,
  });
};

//! We're shelving the service worker code until we either get all edge cases working correctly or removing it
// export const getIDTokenFromServiceWorkerOrFirebase = async () => {
//   if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
//     const serviceWorkerIdTokenPromise = new Promise<string>((resolve) => {
//       const messageChannel = new MessageChannel();
//       messageChannel.port1.onmessage = (event) => {
//         if (event.data.type === 'firebaseIdToken') {
//           resolve(event.data.idToken);
//         }
//       };
//       navigator.serviceWorker.controller?.postMessage({ type: 'getFirebaseIdToken', port: messageChannel.port2 }, [
//         messageChannel.port2,
//       ]);
//     });
//     const idTokenOrTimeout = await Promise.any([serviceWorkerIdTokenPromise, sleep(3000)]);
//     return idTokenOrTimeout || getAuthInstance().currentUser?.getIdToken();
//   }
//   return getAuthInstance().currentUser?.getIdToken();
// };

export const loadIDToken = createAsyncThunk('auth/getIDToken', async (_, thunkAPI) => {
  const state = (await thunkAPI.getState()) as { auth: State };
  // Firebase ID Tokens are valid for an hour
  if (state.auth.firebaseIDTokenFetched && state.auth.firebaseIDTokenFetched + 50 * 60 * 1000 < Date.now()) {
    return state.auth.firebaseIDToken;
  }
  //! Remnant from service-worker
  // const idToken = await getIDTokenFromServiceWorkerOrFirebase();

  const idToken = state.auth.firebaseUser ? await state.auth.firebaseUser.getIdToken() : undefined;

  if (idToken) {
    prepareOfflineTracking(idToken);
  }
  return idToken;
});

export const initiateAnonymousFirebaseLogin = async (dispatch: Dispatch) => {
  dispatch(markFirebaseLoginInitiated());
  try {
    startStopwatch('createAnonymousAuth');
    await signInAnonymously(getAuthInstance());
  } finally {
    dispatch(markFirebaseLoginFinished());
  }
};

export const initiateFirebaseLoginWithToken = async (dispatch: Dispatch, loginToken: string) => {
  dispatch(markFirebaseLoginInitiated());
  try {
    await signInWithCustomToken(getAuthInstance(), loginToken);
  } finally {
    dispatch(markFirebaseLoginFinished());
  }
};

export const initiateFirebaseLoginWithProvider = async (dispatch: Dispatch, provider: AuthProvider) => {
  dispatch(markFirebaseLoginInitiated());
  try {
    await signInWithPopup(getAuthInstance(), provider);
  } finally {
    dispatch(markFirebaseLoginFinished());
  }
};

export const logout = async (dispatch: Dispatch, doNotRefresh?: boolean) => {
  const { currentUser } = getAuthInstance();
  if (currentUser?.isAnonymous) {
    await currentUser.delete();
  }
  dispatch(clear());
  await getAuthInstance().signOut();
  if (doNotRefresh) {
    return;
  }
  // For some reason Firebase sometimes ends up in a state where without this
  // no-one can ever log in anymore. We can only do that if we will refresh, as those
  // get populated on page load.
  indexedDB.deleteDatabase('firebase-installations-database');
  indexedDB.deleteDatabase('firebaseLocalStorageDb');

  window.location.reload();
};

export const fetchSignInMethods = async (dispatch: Dispatch, email: string) => {
  return await fetchSignInMethodsForEmail(getAuthInstance(), email);
};

export const getUserInstance = () => {
  return getAuthInstance().currentUser;
};

export const loginWithEmailAndPassword = async (email: string, password: string): Promise<string> => {
  try {
    await setPersistence(getAuthInstance(), browserLocalPersistence);
    const response = await signInWithEmailAndPassword(getAuthInstance(), email, password);
    if (!response?.user?.emailVerified) {
      return 'unverified';
    } else {
      return 'success';
    }
  } catch (error: any) {
    return error.code;
  }
};

export const sendResetPasswordEmail = async (email: string) => {
  await sendPasswordResetEmail(getAuthInstance(), email);
};

export const createUserNameWithEmailAndPassword = async (email: string, password: string) => {
  await createUserWithEmailAndPassword(getAuthInstance(), email, password);
};

export const signInWithPhoneNumber = async (phone: string, appVerifier: RecaptchaVerifier) => {
  return await firebaseSignInWithPhoneNumber(getAuthInstance(), phone, appVerifier);
};

export const confirmationResult = async (code: string) => {
  try {
    //@ts-ignore
    await window.confirmationResult.confirm(code);
    await setPersistence(getAuthInstance(), browserLocalPersistence);

    return 'success';
  } catch (error: any) {
    return error;
  }
};
