import * as Sentry from '@sentry/react';
import { collection, doc, getDoc } from 'firebase/firestore';

import { getAuthInstance, getFirestoreInstance } from '../../firebase';
import { users } from '../../services/api/ava';
import { scribeSetFontSize } from '../../store/slices/uiState';
import { throttle } from '../../utils/lodash';

export function fetchScribeSettings() {
  return async (dispatch: any, getState: any) => {
    const {
      userProfile: { parse },
    } = getState();
    if (!parse?.avaId) {
      throw new Error('No "avaId" found in "user" object');
    }

    try {
      const firestore = await getFirestoreInstance();
      const usersCollection = collection(firestore, 'users');
      const docRef = doc(usersCollection, parse.avaId);
      const userDoc = await getDoc(docRef);
      const settings = userDoc.data()?.settings;
      if (settings && settings.webFontSize) {
        dispatch(scribeSetFontSize(settings.webFontSize));
      }
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          category: 'firebase',
        },
      });
    }
  };
}

const updateFontSize = throttle(
  (avaId: string, uid: string, fontSize: number) =>
    users.updateSettings({
      avaId,
      firebaseAuthUID: uid,
      settings: {
        webFontSize: fontSize,
      },
    }),
  2000
);

export const scribeSetFontSizeAndSave = (fontSize: number) => (dispatch: any, getState: any) => {
  const {
    userProfile: { parse },
  } = getState();
  const currentUser = getAuthInstance().currentUser;
  if (currentUser) {
    updateFontSize(parse?.avaId, currentUser.uid, fontSize);
    return dispatch(scribeSetFontSize(fontSize));
  }
};
