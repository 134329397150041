import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import type { RoomStatusUpdateAction } from '../../reducers/legacyConversationReducer';
import { selectConfigEnableCCV2, selectConfigEnableConvoV2, selectFirebaseUser } from '../../selectors/auth';
import { selectAvaId, selectFeatures } from '../../selectors/userProfile';
import { getTurnCredentials, guestUsers, users as avaUsers } from '../../services/api/ava';
import { users as saasUsers } from '../../services/api/saas';
import { tauriEventEmit } from '../../services/desktopIntegration';
import type { FeatureMap, Subscription } from '../../types';
import { firebaseFunctionsEndpoint, httpRequestWithToken } from '../../utils/http';
import * as log from '../../utils/log';
import * as segment from '../../utils/segment';
import { startStopwatch, stopAndTrack } from '../../utils/stopwatch';
import { logoutAndClean } from '../../utils/user';
import { setTurnCredentials } from '../../utils/webrtc';
import { getDefaultRoomId } from '../../utils/ws-v1';
import type { RootState } from '../store';
import { scribeDashboardWSUrl } from './scribeDashboard';
import { setV1WebsocketURL } from './v1Session';

// we don't want to await the fetchRemainingCredits call
// to not slow down starting a conversation,
// so we use this as a placeholder for "haven't fetched yet"
export const HAVENT_FETCHED_SCRIBE_CREDITS = undefined;
export interface ParseProfile {
  avaId: string;
  avaName: string;
  userName: string;
  phoneNumber?: string;
  userPhoto?: {
    url: string;
  };
  // Is this set by SET_ORGANIZATION? I think this is currently unset, but the webapp still reads it?
  organization?: any;
  organizationId?: string;
  accountType?: string;
  emails?: any;
  firebaseAuthUID?: string;
}

export interface AvaUser {
  email: string;
  uid: string;
  parse: ParseProfile;
  profile?: {
    hearingProfile?: number;
    id?: string;
    orgProperties?: {
      role?: string;
    };
    role?: string;
  };
  convoMetrics?: any;
  country?: string;
  city?: string;
}

export type State = {
  // avaUser is as received from
  // avaUsers.getOrCreateProfile(...
  // or
  // guestUsers.createGuestProfile(...
  // Keep in mind that some of its properties could be changed since login!
  // Which means for example that avaUser.parse.userName might be different
  // than userName, with userName being more fresh.
  avaUser?: AvaUser | undefined;

  features: FeatureMap;
  updateFeaturesLoading: { isLoading: boolean; cause?: 'save-transcript' | 'cc-v2' | 'speaker-id' | 'convo-v2' };

  parse?: ParseProfile;
  subscription?: Subscription;
  profile?: {
    hearingProfile?: any;
    orgProperties?: {
      role?: string;
    };
    role?: string;
  };
  updateHearingProfileLoading: boolean;

  userProfileFetchInitiated: boolean;
  userProfileFetchFinished: boolean;
  updateUserNameLoading: boolean;
  updateUserNameSuccess: boolean;

  scribeRemainingTime: number | undefined;
  paidASRCreditTime: number;
};

const initialState: State = {
  features: {},
  updateFeaturesLoading: { isLoading: false },

  updateHearingProfileLoading: false,

  userProfileFetchInitiated: false,
  userProfileFetchFinished: false,
  updateUserNameLoading: false,
  updateUserNameSuccess: false,

  scribeRemainingTime: HAVENT_FETCHED_SCRIBE_CREDITS,
  paidASRCreditTime: 0,
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setFeatures(state, { payload }: PayloadAction<FeatureMap>) {
      state.features = payload;
    },
    toggleFeatures(state, { payload }: PayloadAction<string>) {
      state.features[payload] = !state.features[payload];
    },
    setPaidASRCreditTime(state, { payload }) {
      state.paidASRCreditTime = payload;
    },
    decreasePaidASRCreditTime(state) {
      const newTime = state.paidASRCreditTime - 1000;
      if (newTime > 0) state.paidASRCreditTime = newTime;
    },
    setScribeRemainingTime(state, { payload }) {
      state.scribeRemainingTime = payload;
    },
    decreaseScribeRemainingTime(state) {
      const remaining = state.scribeRemainingTime || 0;
      const newTime = remaining - 1000;
      if (newTime > 0) state.scribeRemainingTime = newTime;
    },
    clear(state) {
      Object.assign(state, initialState);
    },
    resetUpdateUserNameSuccess(state) {
      state.updateUserNameSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.userProfileFetchInitiated = false;
        state.userProfileFetchFinished = true;
        if (!action.payload) return;

        state.avaUser = action.payload.user;
        state.features = action.payload.user.features;
        state.subscription = action.payload.user.subscription;
        state.parse = action.payload.user.parse;
        state.profile = action.payload.user.profile;
      })
      .addCase(fetchUserProfile.pending, (state, action) => {
        state.userProfileFetchInitiated = true;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.userProfileFetchInitiated = false;
        state.userProfileFetchFinished = true;
      })

      .addCase(updateUserName.pending, (state) => {
        state.updateUserNameLoading = true;
        state.updateUserNameSuccess = false;
      })
      .addCase(updateUserName.fulfilled, (state, action) => {
        state.updateUserNameLoading = false;
        state.updateUserNameSuccess = true;
        if (state.parse) state.parse.userName = action.payload.userName;
      })
      .addCase(updateUserName.rejected, (state) => {
        state.updateUserNameLoading = false;
        state.updateUserNameSuccess = false;
      })

      .addCase(updateFeatures.pending, (state) => {
        state.updateFeaturesLoading = { isLoading: true, cause: 'save-transcript' };
      })
      .addCase(updateFeatures.rejected, (state) => {
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(updateFeatures.fulfilled, (state, action) => {
        state.features = Object.assign(state.features, action.payload);
        state.updateFeaturesLoading = { isLoading: false };
      })

      .addCase(updateHearingProfile.pending, (state) => {
        state.updateHearingProfileLoading = true;
      })
      .addCase(updateHearingProfile.fulfilled, (state, action) => {
        state.updateHearingProfileLoading = false;
        if (!state.profile) return;
        state.profile.hearingProfile = action.payload;
      })
      .addCase(updateHearingProfile.rejected, (state, action) => {
        state.updateHearingProfileLoading = false;
      })

      .addCase(getOrganization.fulfilled, (state, action) => {
        if (!state.parse) return;
        state.parse.organization = action.payload;
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        if (!state.profile) {
          state.profile = {};
        }
        if (!state.profile.orgProperties) {
          state.profile.orgProperties = {};
        }
        if (!state.profile.role && (!state.profile.orgProperties || !state.profile.orgProperties.role)) return;
        state.profile.role = action.payload;
        state.profile.orgProperties.role = action.payload;
      })
      .addCase(toggleSpeakerId.fulfilled, (state, action) => {
        state.features = Object.assign(state.features, action.payload);
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleSpeakerId.rejected, (state) => {
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleSpeakerId.pending, (state) => {
        state.updateFeaturesLoading = { isLoading: true, cause: 'speaker-id' };
      })
      .addCase(toggleDesktopV2.fulfilled, (state, action) => {
        state.features = Object.assign(state.features, action.payload);
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleDesktopV2.rejected, (state) => {
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleDesktopV2.pending, (state) => {
        state.updateFeaturesLoading = { isLoading: true, cause: 'cc-v2' };
      })
      .addCase(toggleConvoV2.fulfilled, (state, action) => {
        state.features = Object.assign(state.features, action.payload);
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleConvoV2.rejected, (state) => {
        state.updateFeaturesLoading = { isLoading: false };
      })
      .addCase(toggleConvoV2.pending, (state) => {
        state.updateFeaturesLoading = { isLoading: true, cause: 'convo-v2' };
      });

    builder.addCase('ROOM_STATUS_UPDATE', (state, action: RoomStatusUpdateAction) => {
      const isUserHost = state?.parse?.avaId === action.status?.host?.avaId;
      if (!isUserHost) return;
      const hostFlags = action.status?.host?.flags;
      if (hostFlags) {
        state.features = Object.assign(state.features, hostFlags);
      }
    });
  },
});

export const userProfileReducer = userProfileSlice.reducer;
export const {
  clear,
  decreasePaidASRCreditTime,
  decreaseScribeRemainingTime,
  setPaidASRCreditTime,
  setScribeRemainingTime,
  resetUpdateUserNameSuccess,
} = userProfileSlice.actions;

export const fetchUserProfile = createAsyncThunk('userProfile/fetchUserProfile', async (_, thunkAPI) => {
  const state = (await thunkAPI.getState()) as RootState;
  const dispatch = thunkAPI.dispatch;

  const firebaseUser = state.auth.firebaseUser;
  const firebaseAuthUID = firebaseUser?.uid;
  if (!firebaseUser || !firebaseAuthUID) return;

  let channel: string;
  if (window.__TAURI__) {
    channel = 'desktop-v2';
  } else {
    channel = 'web-v2';
  }

  getTurnCredentials().then((turnCredentials) => {
    if (window.__TAURI__) {
      tauriEventEmit('webrtc-turn-credentials', {
        turnCredentials,
      });
    } else {
      setTurnCredentials(turnCredentials);
    }
  });

  const avaId = localStorage.getItem('avaId');
  let response;
  if (firebaseUser.isAnonymous) {
    if (!avaId) {
      // new guest user
      startStopwatch('createGuestProfile');
      response = await guestUsers.createGuestProfile({
        roomId: firebaseUser.uid && getDefaultRoomId(firebaseUser.uid),
        firebaseAuthUID,
      });
      stopAndTrack('createGuestProfile');
      stopAndTrack('userProfile', { guest: true, new: true });
    } else {
      // existing guest user
      try {
        response = await guestUsers.getGuestProfile({ avaId, firebaseAuthUID });
        stopAndTrack('userProfile', { guest: true, new: false });
      } catch (e: any) {
        // TODO: Add some tracking here.
        segment.track('Web - Login Error - Guest Profile', { error: e });
        localStorage.removeItem('avaId');
      }
    }
  } else {
    if (!firebaseAuthUID) {
      // This can only be thrown if there is an error in our code, never due to
      // external circumstance.
      throw new Error("Can't fetch non-anonymous user-profile without firebase user");
    }
    try {
      // Despite the name, this function cannot currently (05/16/2022) create
      // a profile. The ava-backend cannot do that at all, only saas-backend can.
      // That's why if that fails with a 400 and a custom_code of 104, we need to
      // create the profile via saas-backend, and then re-fetch it through ava-backend
      // in order to have complete data. This is slow, but only affects new users,
      // and can be cleaned up if the ava-backend ever allows user creation.
      response = await avaUsers.getOrCreateProfile({ firebaseAuthUID, channel });
      stopAndTrack('userProfile', { guest: false, new: false });
    } catch (e: any) {
      try {
        if (!e.response || !e.response.data || e.response.data.custom_error_code !== 104 || e.response.status !== 400) {
          segment.track('Web - Login Error - Regular Profile', { error: e });
          throw e;
        }
        // This is able to create a profile, but returns an incomplete set of feature flags
        // However - it often returns errors despite successfully creating the user!
        // Welcome to try-catch madness.
        try {
          await saasUsers.getOrCreateUser({});
        } catch (e: any) {}
        // That's why we need to refetch the profile from avaUsers.
        response = await avaUsers.getOrCreateProfile({ firebaseAuthUID, channel });
        stopAndTrack('userProfile', { guest: false, new: true });
      } catch (e: any) {
        if (e.toJSON && e.toJSON().message === 'Network Error') {
          // If it's a network error - do not log out, because we might successfully
          // reconnect eventually.
          return;
        }
        segment.track('Web - Login Error - Regular Profile', { error: e });
        logoutAndClean();
      }
    }
  }
  const { user, wsUrl, scribeUrl } = response.data;
  response.data = null;
  response = null as any;
  dispatch(scribeDashboardWSUrl(scribeUrl));
  dispatch(setV1WebsocketURL(wsUrl));

  if (!firebaseUser.isAnonymous) {
    dispatch(getOrganization());
  }

  log.updateUserInfo({
    name: user.parse.userName,
    avaName: user.parse.avaName,
    firebaseAuthUid: user.parse.firebaseAuthUID,
    avaId: user.parse.avaId,
  });

  return { user, wsUrl, scribeUrl };
});

export const updateUserName = createAsyncThunk('userProfile/updateUserName', async (userName: string, thunkAPI) => {
  const state = (await thunkAPI.getState()) as RootState;
  const avaId = state.userProfile.parse?.avaId;
  const firebaseUser = state.auth.firebaseUser;
  if (!avaId || !firebaseUser) throw new Error('avaId missing');
  await avaUsers.updateUserName({
    avaId,
    firebaseAuthUID: firebaseUser.uid,
    userName,
  });
  return { userName, avaId };
});

export const updateUserRole = createAsyncThunk('userProfile/updateUserRole', async (role: string, thunkAPI) => {
  // TODO: Should this issue an API call?
  return role;
});

export const updateFeatures = createAsyncThunk('userProfile/updateFeatures', async (features: FeatureMap, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const firebaseUser = selectFirebaseUser(state);
  const avaId = selectAvaId(state);
  if (!firebaseUser || !avaId) throw new Error('logged out');
  await avaUsers.updateFeatures({ avaId, firebaseAuthUID: firebaseUser.uid, features });
  return features;
});

export const updateHearingProfile = createAsyncThunk(
  'userProfile/updateHearingProfile',
  async (hearingProfile: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const firebaseUser = selectFirebaseUser(state);
    const avaId = selectAvaId(state);

    if (!firebaseUser || !avaId) throw new Error('logged out');

    await avaUsers.updateHearingProfile({ avaId: avaId, firebaseAuthUID: firebaseUser.uid, hearingProfile });
    return hearingProfile;
  }
);

export const getOrganization = createAsyncThunk('userProfile/fetchOrganization', async (_, thunkAPI) => {
  const { language } = i18n;
  let res = await saasUsers.getUser(language);
  const organization = res.data.organization;
  res.data = null;
  res = null as any;
  return organization;
});

export const toggleSpeakerId = createAsyncThunk('userProfile/toggleSpeakerId', async (_, thunkAPI) => {
  const platform = `Ava ${window.isElectron || window.__TAURI__ ? 'Desktop' : 'Web'}`;
  const url = `${firebaseFunctionsEndpoint}/enrollBetaFeature?platform=${encodeURIComponent(platform)}`;
  const state = thunkAPI.getState() as RootState;
  const features = selectFeatures(state);
  const isEnabled = features['mono-segmentation'] && features['nats'];
  try {
    const res = await httpRequestWithToken({
      url,
      method: 'PATCH',
      payload: { beta_feature_name: 'solo_speaker_id', enable_feature: !isEnabled },
    });
    if (res.status === 200) {
      if (window.__TAURI__) {
        tauriEventEmit('speaker_id_toggled', res.data.flags);
      }
      return res.data.flags;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({});
  }
});

export const toggleDesktopV2 = createAsyncThunk('userProfile/toggleDesktopV2', async (_, thunkAPI) => {
  const platform = `Ava ${window.isElectron || window.__TAURI__ ? 'Desktop' : 'Web'}`;
  const url = `${firebaseFunctionsEndpoint}/enrollBetaFeature?platform=${encodeURIComponent(platform)}`;

  const state = thunkAPI.getState() as RootState;
  const features = selectFeatures(state);
  const hasInFirebase = selectConfigEnableCCV2(state);
  const isEnabled = features['desktop-v2'] !== false && hasInFirebase;
  try {
    const res = await httpRequestWithToken({
      url,
      method: 'PATCH',
      payload: { beta_feature_name: 'desktop_v2', enable_feature: !isEnabled },
    });
    if (res.status === 200) {
      return res.data.flags;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({});
  }
});

export const toggleConvoV2 = createAsyncThunk('userProfile/toggleConvoV2', async (_, thunkAPI) => {
  const platform = `Ava ${window.isElectron || window.__TAURI__ ? 'Desktop' : 'Web'}`;
  const url = `${firebaseFunctionsEndpoint}/enrollBetaFeature?platform=${encodeURIComponent(platform)}`;

  const state = thunkAPI.getState() as RootState;
  const features = selectFeatures(state);
  const hasInFirebase = selectConfigEnableConvoV2(state);
  const isEnabled = features['convo-v2'] !== false && hasInFirebase;

  try {
    const res = await httpRequestWithToken({
      url,
      method: 'PATCH',
      payload: { beta_feature_name: 'convo_v2', enable_feature: !isEnabled },
    });
    if (res.status === 200) {
      return res.data.flags;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({});
  }
});
