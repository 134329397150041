import { getAnalytics } from 'firebase/analytics';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import type { Firestore } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getPerformance, initializePerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';

import { getBackendConfig } from '../utils/backends';

// Initialize Firebase app
const app = getApps().length ? getApp() : initializeApp(getBackendConfig('firebaseConfig'));

// Initialize Performance Monitoring
initializePerformance(app);

// Initialize Firebase services
let authInstance = getAuth(app);
let analyticsInstance = getAnalytics(app);
let performanceInstance = getPerformance(app);
let remoteConfigInstance = getRemoteConfig(app);
let firestoreInstance: Firestore | undefined = undefined;

// Exported functions for accessing and setting instances
export const getAuthInstance = () => authInstance;
export const setAuthInstance = (instance: any) => {
  authInstance = instance;
};

export const getAnalyticsInstance = () => analyticsInstance;
export const setAnalyticsInstance = (instance: any) => {
  analyticsInstance = instance;
};

export const getPerformanceInstance = () => performanceInstance;
export const setPerformanceInstance = (instance: any) => {
  performanceInstance = instance;
};

export const getRemoteConfigInstance = () => remoteConfigInstance;
export const setRemoteConfigInstance = (instance: any) => {
  remoteConfigInstance = instance;
};
if (remoteConfigInstance) {
  remoteConfigInstance.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
}

export const getFirestoreInstance = async () => {
  if (!firestoreInstance) firestoreInstance = getFirestore(app);
  return firestoreInstance;
};
export const setFirestoreInstance = (instance: Firestore) => {
  firestoreInstance = instance;
};

// Initialize authentication providers
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');

export const restName = 'firebase-mobile';

export default app;
